// Slider Carousel - Headers
    function carousel() {
        owl = $(".owl-carousel");

        owl.owlCarousel({
            items: 1,
            nav: true,
            dots: true,
            loop: true,
            navText: ['<i class="fa fa-chevron-left"></i>', '<i class="fa fa-chevron-right"></i>'],
            slideBy: 1,
            autoplay: true,
            autoplayTimeout: 6500,
            autoplayHoverPause: true,
            mouseDrag: false,
            smartSpeed: 600,
        });
    }

// Slider Carousel - Search Results images
    function carouselSearchResults() {
        owl = $(".result-package-carousel");

        owl.owlCarousel({
            items: 1,
            nav: true,
            dots: false,
            loop: false,
            navText: ['<i class="fa fa-chevron-circle-left"></i>', '<i class="fa fa-chevron-circle-right"></i>'],
            slideBy: 1,
            autoplay: false,
        });
    }


// Package Gallery Carousel
    function carouselGallery() {
        owl = $(".owl-carousel-package-gallery");

        owl.owlCarousel({
            items: 5,
            nav: true,
            dots: false,
            loop: true,
            navText: ['<i class="fa fa-arrow-circle-left"></i>', '<i class="fa fa-arrow-circle-right"></i>'],
            slideBy: 2,
            autoplay: false,
            mouseDrag: true,
            smartSpeed: 600,
            autoWidth: true,
        });
    }

// Slider Carousel - Tours
    function carouselTours() {
        owlTour = $(".owl-carousel-tours");

        owlTour.owlCarousel({
            items: 1,
            nav: true,
            dots: false,
            loop: false,
            navText: ['<i class="fa fa-chevron-left tour-control"></i>', '<i class="fa fa-chevron-right tour-control"></i>'],
            slideBy: 1,
            autoplay: true,
            autoplayTimeout: 6500,
            autoplayHoverPause: true,
            mouseDrag: false,
            smartSpeed: 600,
        });

    }

// Tours Carousel Slider Controls
    owlTour = $(".owl-carousel-tours");

    function anchorsTours(event) {

        var item = event.item.index;
        //console.log(item);

        var bullets = $('#slider-controls .slide-select');
        var controlOne = $('#slider-controls').find('.owl-dot-one');
        var controlTwo = $('#slider-controls').find('.owl-dot-two');
        var controlThree = $('#slider-controls').find('.owl-dot-three');
        var controlFour = $('#slider-controls').find('.owl-dot-four');
        var controlFive = $('#slider-controls').find('.owl-dot-five');
        var controlSix = $('#slider-controls').find('.owl-dot-six');
        var pageOne = 0;
        var pageTwo = 1;
        var pageThree = 2;
        var pageFour = 3;
        var pageFive = 4;
        var pageSix = 5;

        if (item == 0) {
            bullets.removeClass('active');
            controlOne.addClass('active');
        }
        if (item == 1) {
            bullets.removeClass('active');
            controlTwo.addClass('active');
        }
        if (item == 2) {
            bullets.removeClass('active');
            controlThree.addClass('active');
        }
        if (item == 3) {
            bullets.removeClass('active');
            controlFour.addClass('active');
        }
        if (item == 4) {
            bullets.removeClass('active');
            controlFive.addClass('active');
        }
        if (item == 5) {
            bullets.removeClass('active');
            controlSix.addClass('active');
        }

        controlOne.click(function() {
            owlTour.trigger("to.owl.carousel", [pageOne, 300, true]);
            bullets.removeClass('active');
            $(this).addClass('active');
            
        });
        controlTwo.click(function() {
            owlTour.trigger("to.owl.carousel", [pageTwo, 300, true]);
            bullets.removeClass('active');
            $(this).addClass('active');
        });
        controlThree.click(function() {
            owlTour.trigger("to.owl.carousel", [pageThree, 300, true]);
            bullets.removeClass('active');
            $(this).addClass('active');
        });
        controlFour.click(function() {
            owlTour.trigger("to.owl.carousel", [pageFour, 300, true]);
            bullets.removeClass('active');
            $(this).addClass('active');
        });
        controlFive.click(function() {
            owlTour.trigger("to.owl.carousel", [pageFive, 300, true]);
            bullets.removeClass('active');
            $(this).addClass('active');
        });
        controlSix.click(function() {
            owlTour.trigger("to.owl.carousel", [pageSix, 300, true]);
            bullets.removeClass('active');
            $(this).addClass('active');
        });

    }

    owlTour.on('changed.owl.carousel', function(event) {
        anchorsTours(event);
    });

// Slider Carousel - Flight
    function carouselFlights() {
        owlFlights = $(".owl-carousel-flights");

        owlFlights.owlCarousel({
            items: 1,
            nav: false,
            dots: false,
            loop: false,
            navText: ['<i class="fa fa-chevron-left tour-control"></i>', '<i class="fa fa-chevron-right tour-control"></i>'],
            slideBy: 1,
            autoplay: false,
            autoplayTimeout: 1500,
            autoplayHoverPause: true,
            mouseDrag: true,
            smartSpeed: 600,
        });

    }

// Tours Carousel Slider Controls
    owlFlights = $(".owl-carousel-flights");

    function anchorsFlights(event) {

        var item = event.item.index;
        //console.log(item);

        var bullets = $('#slider-controls .slide-select');
        var controlOne = $('#slider-controls').find('.owl-dot-one');
        var controlTwo = $('#slider-controls').find('.owl-dot-two');
        var pageOne = 0;
        var pageTwo = 1;

        if (item == 0) {
            bullets.removeClass('active');
            controlOne.addClass('active');
        }
        if (item == 1) {
            bullets.removeClass('active');
            controlTwo.addClass('active');
        }

        controlOne.click(function() {
            owlFlights.trigger("to.owl.carousel", [pageOne, 300, true]);
            bullets.removeClass('active');
            $(this).addClass('active');
            
        });
        controlTwo.click(function() {
            owlFlights.trigger("to.owl.carousel", [pageTwo, 300, true]);
            bullets.removeClass('active');
            $(this).addClass('active');
        });
        
    }

    owlFlights.on('changed.owl.carousel', function(event) {
        anchorsFlights(event);
    });

// Package Info Pages Carousel
    function carouselPages() {
        owl = $(".owl-carousel-pages");

        owl.owlCarouselOffTranslate({
            items: 1,
            nav: false,
            dots: false,
            loop: false,
            slideBy: 1,
            autoplay: false,
            mouseDrag: false,
            autoHeight : true
        });
    }

// Pages Slider Controls
    owlPages = $(".owl-carousel-pages");

    function anchorsPages(event) {

        var item = event.item.index;
        //console.log(item);

        var bullets = $('#slider-controls .slide-select');
        var controlOne = $('#slider-controls').find('.owl-dot-one');
        var controlTwo = $('#slider-controls').find('.owl-dot-two');
        var controlThree = $('#slider-controls').find('.owl-dot-three');
        var controlFour = $('#slider-controls').find('.owl-dot-four');
        var controlFive = $('#slider-controls').find('.owl-dot-five');
        var controlSix = $('#slider-controls').find('.owl-dot-six');
        var pageOne = 0;
        var pageTwo = 1;
        var pageThree = 2;
        var pageFour = 3;
        var pageFive = 4;
        var pageSix = 5;

        if (item == 2) {
            bullets.removeClass('active');
            controlOne.addClass('active');
        }
        if (item == 3) {
            bullets.removeClass('active');
            controlTwo.addClass('active');
        }
        if (item == 4) {
            bullets.removeClass('active');
            controlThree.addClass('active');
        }
        if (item == 5) {
            bullets.removeClass('active');
            controlFour.addClass('active');
        }
        if (item == 6) {
            bullets.removeClass('active');
            controlFive.addClass('active');
        }
        if (item == 7) {
            bullets.removeClass('active');
            controlSix.addClass('active');
        }

        controlOne.click(function() {
            owlPages.trigger("to.owl.carousel", [pageOne, 300, true]);
            bullets.removeClass('active');
            $(this).addClass('active');
        });
        controlTwo.click(function() {
            owlPages.trigger("to.owl.carousel", [pageTwo, 300, true]);
            bullets.removeClass('active');
            $(this).addClass('active');
        });
        controlThree.click(function() {
            owlPages.trigger("to.owl.carousel", [pageThree, 300, true]);
            bullets.removeClass('active');
            $(this).addClass('active');
        });
        controlFour.click(function() {
            owlPages.trigger("to.owl.carousel", [pageFour, 300, true]);
            bullets.removeClass('active');
            $(this).addClass('active');
        });
        controlFive.click(function() {
            owlPages.trigger("to.owl.carousel", [pageFive, 300, true]);
            bullets.removeClass('active');
            $(this).addClass('active');
        });
        controlSix.click(function() {
            owlPages.trigger("to.owl.carousel", [pageSix, 300, true]);
            bullets.removeClass('active');
            $(this).addClass('active');
        });

    }

    owlPages.on('changed.owl.carousel', function(event) {
        anchorsPages(event);
    });

// On Scroll Active Anchors 
    function onScrollItinerary(event){
      var scrollPos = $(window).scrollTop();
      $('.anchors-itinerary a').each(function () {
        var currLink = $(this);
        var refElement = $(currLink.attr("href"));
        if (refElement.position().top <= scrollPos && refElement.position().top + refElement.height() > scrollPos) {
            if (!$(this).hasClass('active')) {
                console.log( $(this).attr("href") + ' ativo' );
                $('.anchors a').removeClass("active");
                currLink.addClass("active");
            }
        } else {
          //currLink.removeClass("active");
        }
      });
    }

// On Scroll Active Anchors 
    function onScroll(event){
      var scrollPos = $(window).scrollTop();
      $('.owl-item.active .anchors a').each(function () {
        var currLink = $(this);
        var refElement = $(currLink.attr("href"));
        if (refElement.position().top <= scrollPos && refElement.position().top + refElement.height() > scrollPos) {
            if (!$(this).hasClass('active')) {
                console.log( $(this).attr("href") + ' ativo' );
                $('.anchors a').removeClass("active");
                currLink.addClass("active");
            }
        } else {
          //currLink.removeClass("active");
        }
      });
    }

// Range Sliders - Search Filter Price
    function rangeSlider() {
        $('#slider-price').noUiSlider({
            start: [ 4000, 8000 ],
            snap: false,
            connect: true,
            range: {
                'min': [     1000 ],
                '10%': [   4000,  500 ],
                '50%': [  12000, 1000 ],
                'max': [ 30000 ]
            },
            format: wNumb({
                decimals: 0,
                thousand: '.',
                prefix: 'R$ ',
            })
        });
        $('#slider-date').noUiSlider({
            start: [ 5, 12 ],
            snap: false,
            connect: true,
            range: {
                'min': [     3 ],
                'max': [ 28 ]
            },
            format: wNumb({
                decimals: 0,
            })
        });
        $('#slider-passenger').noUiSlider({
            start: [ 2, 7 ],
            snap: false,
            connect: true,
            range: {
                'min': [     2 ],
                'max': [ 7 ]
            },
            format: wNumb({
                decimals: 0,
            })
        });
        $('#slider-date').Link('lower').to($('#slider-date-value-lower'));
        $('#slider-date').Link('upper').to($('#slider-date-value-upper'));
        $('#slider-price').Link('lower').to($('#slider-price-value-lower'));
        $('#slider-price').Link('upper').to($('#slider-price-value-upper'));
        $('#slider-passenger').Link('lower').to($('#slider-passenger-value-lower'));
        $('#slider-passenger').Link('upper').to($('#slider-passenger-value-upper'));
    }

// Header Functions - Modals
    function modals() {
        var currency = $('#currenvy-button');
        var voucher = $('#voucher-button');
        var account = $('#account-button');
        var closeButton = $("#exchange").add("#voucher").add("#login").find('.close-modal');
        var closeArea = $("#exchange").add("#voucher").add("#login").find('.overlay');

        currency.click(function() {
            setTimeout(function(){
                $("#exchange").addClass('active');
            }, 10);
        });

        voucher.click(function() {
            setTimeout(function(){
                $("#voucher").addClass('active');
            }, 10);
        });
        account.click(function() {
            setTimeout(function(){
                $("#login").addClass('active');
            }, 10);
        });

        closeButton.click(function() {
            $("#exchange").add("#voucher").add("#login").removeClass('active');
        });
        closeArea.click(function() {
            $("#exchange").add("#voucher").add("#login").removeClass('active');
        });
        
    }

// PAYMENT METHODS ACCORDION
    function paymentMethod() {
        var paymentSelector = $('.payment-method-selector');

        paymentSelector.click(function() {
            $('.section-form').removeClass('active');
            $('.section-form input').filter(':checkbox').prop('checked',false);
            $(this).filter(':checkbox').prop('checked',true);
            $(this).parent().parent().toggleClass('active');
        });
    }
   

// GENERAL FUNCTIONS

$(document).ready(function() {

    // CAROUSEL INNIT
    modals();
    carousel();
    carouselTours();
    carouselGallery();
    carouselPages();
    carouselFlights();
    carouselSearchResults();
    paymentMethod();
    smoothScroll.init({
        updateURL: false,
        callbackBefore: function ( toggle, anchor ) {
        },
        callbackAfter: function ( toggle, anchor ) {
            $('[data-scroll]').removeClass('active');
            $(toggle).addClass('active');
        }
    });
    rangeSlider();
    
    $(".sticky").stick_in_parent({
        offset_top : 20
    });

    // DATE PICKER - DAY AND months
    $('.datepicker-month').pikaday({
        firstDay: 1,
        i18n: {
            previousMonth: 'Mês anterior',
            nextMonth: 'Próximo mês',
            months: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
            weekdays: ['Domingo', 'Segunda-feira', 'Terça-feira', 'Quarta-feira', 'Quinta-feira', 'Sexta-feira', 'Sábado'],
            weekdaysShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab']
        },
        defaultDate: new Date(),
        setDefaultDate: true,
        format: 'MMMM [de] YYYY'
    });
    $('.datepicker-day').pikaday({
        firstDay: 1,
        i18n: {
            previousMonth: 'Mês anterior',
            nextMonth: 'Próximo mês',
            months: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
            weekdays: ['Domingo', 'Segunda-feira', 'Terça-feira', 'Quarta-feira', 'Quinta-feira', 'Sexta-feira', 'Sábado'],
            weekdaysShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab']
        },
        defaultDate: new Date(),
        setDefaultDate: true,
        format: 'DD/MM/YYYY',
        numberOfMonths: 1
    });

    // SHOW AND HIDE HOME FORMS 
    var buttonPackage = $('#package-form-select');
    var buttonCustomPackage = $('#custom-package-form-select');
    var packageForm = $('#package-form');
    var customPackageForm = $('#custom-package-form');

    buttonPackage.click(function() {
        buttonPackage.addClass('active');
        packageForm.addClass('active');
        buttonCustomPackage.removeClass('active');
        customPackageForm.removeClass('active');
    });
    buttonCustomPackage.click(function() {
        buttonCustomPackage.addClass('active');
        customPackageForm.addClass('active');
        buttonPackage.removeClass('active');
        packageForm.removeClass('active');
    });

    $('#car-return-city').change(function(){
        $('#car-return-city-input').toggle();
    });

    // MAPS 
    // When the window has finished loading create our google map below
    google.maps.event.addDomListener(window, 'load', init);

    function init() {
        // Basic options for a simple Google Map
        // For more options see: https://developers.google.com/maps/documentation/javascript/reference#MapOptions
        var mapOptions = {
            // How zoomed in you want the map to start at (always required)
            zoom: 6,

            // The latitude and longitude to center the map (always required)
            center: new google.maps.LatLng(19.3200988, -99.1521845), // Cidade do México

            // Controls Exibition
            panControl: false,
            zoomControl: true,
            mapTypeControl: false,
            scaleControl: false,
            streetViewControl: false,
            overviewMapControl: false,
            // This is where you would paste any style found on Snazzy Maps.
            styles: 
            // Options
                [{
                    "featureType": "landscape.natural",
                    "elementType": "geometry.fill",
                    "stylers": [{
                        "visibility": "on"
                    }, {
                        "color": "#e0efef"
                    }]
                }, {
                    "featureType": "poi",
                    "elementType": "geometry.fill",
                    "stylers": [{
                        "visibility": "on"
                    }, {
                        "hue": "#1900ff"
                    }, {
                        "color": "#c0e8e8"
                    }]
                }, {
                    "featureType": "road",
                    "elementType": "geometry",
                    "stylers": [{
                        "lightness": 100
                    }, {
                        "visibility": "simplified"
                    }]
                }, {
                    "featureType": "road",
                    "elementType": "labels",
                    "stylers": [{
                        "visibility": "off"
                    }]
                }, {
                    "featureType": "transit.line",
                    "elementType": "geometry",
                    "stylers": [{
                        "visibility": "on"
                    }, {
                        "lightness": 700
                    }]
                }, {
                    "featureType": "water",
                    "elementType": "all",
                    "stylers": [{
                        "color": "#7dcdcd"
                    }]
                }]

        };

        // Get the HTML DOM element that will contain your map 
        // We are using a div with id="map" seen below in the <body>
        var mapElement = document.getElementById('package-map');

        // Create the Google Map using our element and options defined above
        var map = new google.maps.Map(mapElement, mapOptions);

        // Let's also add a marker while we're at it
        var marker = new google.maps.Marker({
            position: new google.maps.LatLng(40.6700, -73.9400),
            map: map,
            title: 'Snazzy!'
        });
    }
});

$(document).on("scroll", onScroll);
//$(document).on("scroll", onScrollVisa);

$(window).on("load resize orientationchange", function() {
    var windowHeight = $(window).height();
    var windowWidth =$(window).width();
    var row = $(".row");
    var fullscreen = $('.fullscreen');

    // CAROUSEL HEIGHT
    row.css({
        'min-height': windowHeight * 0.65
    });
    fullscreen.css({
        'height': windowHeight,
        'width': windowWidth,
        'top': '0'
    });

});
