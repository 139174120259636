function galleryDestiny(action) {
  if (action == 'next') {
    actions = ['first', 'append']
  } else {
    actions = ['last', 'prepend']
  }
  if ($('#gallery-destiny-guide a.open')[action]().length == 0) {
    newGalleryDestiny($('#gallery-destiny-guide a')[actions[0]]())
    return
  }
  $('#zoom .container img')[actions[0]]().remove();
  $('#zoom .container img.open, #gallery-destiny-guide a.open').removeClass('open')[action]().addClass('open');
  $('#zoom .container')[actions[1]](
    '<img src="'+ $('#gallery-destiny-guide a.open')[action]()[action]().attr('href') +'" />'
  );
}

function newGalleryDestiny(image) {
  $('#gallery-destiny-guide a').removeClass('open');
  image.addClass('open');
  $('#zoom').addClass('show').find('.container').html(
    '<img src="'+ image.prev().prev().attr('href')+'" />'+
    '<img src="'+ image.prev().attr('href')+'" />'+
    '<img class="open" src="'+ image.attr('href')+'" />'+
    '<img src="'+ image.next().attr('href')+'" />'+
    '<img src="'+ image.next().next().attr('href')+'" />'
  );
}

