function gallery(action) {
  if (action == 'next') {
    actions = ['first', 'append']
  } else {
    actions = ['last', 'prepend']
  }
  if ($('#gallery a.open').parent()[action]().find('a').length == 0) {
    newGallery($('#gallery a')[actions[0]]())
    return
  }
  $('#zoom .container img')[actions[0]]().remove();
  $('#zoom .container img.open').removeClass('open')[action]().addClass('open');
  $('#gallery a.open').removeClass('open').parent()[action]().find('a').addClass('open');
  $('#zoom .container')[actions[1]](
    '<img src="'+ $('#gallery a.open').parent()[action]().find('a').parent()[action]().find('a').attr('href') +'" />'
  );
}

function newGallery(image) {
  $('#gallery a').removeClass('open');
  image.addClass('open');
  $('#zoom').addClass('show').find('.container').html(
    '<img src="'+ image.parent().prev().prev().find('a').attr('href')+'" />'+
    '<img src="'+ image.parent().prev().find('a').attr('href')+'" />'+
    '<img class="open" src="'+ image.attr('href')+'" />'+
    '<img src="'+ image.parent().next().find('a').attr('href')+'" />'+
    '<img src="'+ image.parent().next().next().find('a').attr('href')+'" />'
  );
}

